import React, { useImperativeHandle } from 'react';

import * as Yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';

import { Controller, useForm } from 'react-hook-form';

import { EditOrganizationRequest } from '../../../../graphql/mutations/editOrganization';
import { UploadAvatarResponse } from '../../../../graphql/mutations/uploadAvatar';
import { FormComponentProps, FormRef } from '../../../../models/form';
import { Spacer } from '../../Spacer';
import TextField from '../../TextField';
import UploadButton from '../../UploadButton';
import FormSelectField, { SelectOption } from '../../FormSelectField/FormSelectField';
import { HasPermission } from '../../Security';
import { RoleEnum } from '../../../../models/session';
import { CountryCode } from '../../../utils/types';

const ValidationSchema = Yup.object().shape({
  avatarId: Yup.number().default(0).required(),
  name: Yup.string().default('').required(),
  website: Yup.string().default('').required(),
  address: Yup.string().default('').required(),
  city: Yup.string().default('').required(),
  state: Yup.string().default('').required(),
  country: Yup.string().default('').required(),
  pocUserId: Yup.string().notRequired().nullable(),
});

export interface EditOrganizationFormValue extends Omit<EditOrganizationRequest, 'id'> {}
export interface EditOrganizationFormProps extends FormComponentProps<EditOrganizationFormValue> {
  userOptions: SelectOption[];
  partnerOptions: SelectOption[];
}

const EditOrganizationForm = React.forwardRef<FormRef<EditOrganizationFormValue>, EditOrganizationFormProps>(
  (props: EditOrganizationFormProps, ref) => {
    const { handleSubmit, control, errors, formState, setValue, watch, trigger } = useForm<EditOrganizationFormValue>({
      resolver: yupResolver(ValidationSchema),
      mode: 'onChange',
      defaultValues: props.initialValues,
    });

    const countryOptions = Object.values(CountryCode).map((country) => ({
      value: country,
      label: country,
    }));

    const onSubmit = (data: EditOrganizationFormValue) => {
      props.onCompleted(data);
    };

    const onFileChange = (data: UploadAvatarResponse) => {
      const {
        uploadUserAvatar: { id },
      } = data;
      setValue('avatarId', id);
    };

    const submitHandler = handleSubmit(onSubmit);
    useImperativeHandle(
      ref,
      () => ({
        formState,
        submit: submitHandler,
      }),
      [formState, submitHandler]
    );
    return (
      <form onSubmit={submitHandler}>
        <Controller
          render={() => {
            return (
              <UploadButton
                size={'large'}
                onChange={onFileChange}
                defaultAvatar={{
                  id: props.initialValues?.avatarId || 0,
                  signedUrl: props.initialValues?.avatarImg || '',
                }}
              />
            );
          }}
          name="avatarId"
          control={control}
          errors={errors}
        />
        <TextField
          type="text"
          name="name"
          label="NAME"
          placeholder="Organization Name"
          control={control}
          errors={errors}
        />
        <Spacer type="stack" size="l" />
        <TextField
          type="text"
          name="website"
          label="WEBSITE"
          placeholder="Organization address"
          control={control}
          errors={errors}
        />
        <Spacer type="stack" size="l" />
        <TextField
          type="text"
          name="address"
          label="ADDRESS"
          placeholder="Legal company address"
          control={control}
          errors={errors}
        />
        <Spacer type="stack" size="m" />
        <TextField type="text" name="city" label="CITY" placeholder="City" control={control} errors={errors} />
        <Spacer type="stack" size="m" />
        <TextField type="text" name="state" label="STATE" placeholder="State" control={control} errors={errors} />
        <Spacer type="stack" size="m" />
        <FormSelectField
          name="country"
          label="COUNTRY"
          placeholder="Select the country"
          defaultValue={props?.initialValues?.country}
          value={watch('country')}
          onChange={(value) => {
            setValue('country', value ?? null);
            trigger(['country']);
          }}
          options={countryOptions}
          control={control}
          errors={errors}
        />
        <Spacer type="stack" size="m" />
        <FormSelectField
          name="pocUserId"
          label="POINT OF CONTACT"
          placeholder="Select the POC for the organization"
          defaultValue={props.initialValues?.pocUserId}
          options={props.userOptions}
          control={control}
          errors={errors}
        />
        <Spacer type="stack" size="m" />
        <HasPermission allowedRoles={[RoleEnum.SuperAdmin]}>
          <FormSelectField
            name="partnerId"
            label="PARTNER"
            placeholder="Select the partner for the organization"
            defaultValue={props.initialValues?.partnerId}
            options={props.partnerOptions}
            control={control}
            errors={errors}
          />
        </HasPermission>
      </form>
    );
  }
);

export default EditOrganizationForm;
