import React, { useCallback } from 'react';
import {
  Card,
  Typography,
  Row,
  Col,
  Spin,
  Divider,
  Layout,
  Button,
  List,
  Tag,
  Tooltip,
  Collapse,
  message,
  Select,
} from 'antd';
import { useParams } from 'react-router-dom';
import { useQuery, gql, useMutation } from '@apollo/client';
import { FaRegClock, FaSyncAlt, FaRegTimesCircle } from 'react-icons/fa';
import { REFRESH_LOCATION_HEALTH_SUMMARY } from '../../../graphql/mutations/refreshLocationHealthSummary';
import * as Sentry from '@sentry/react';
import { displayGqlErrors } from '../../../components/ui/ErrorList';
import NotesSection from '../../../components/ui/Notes/NotesSection';
import { UPDATE_LOCATION_HEALTH_SUMMARY } from '../../../graphql/mutations/updateLocationHealthSummary';
import confirm from 'antd/lib/modal/confirm';
import { DownOutlined, ExclamationCircleOutlined, UpOutlined } from '@ant-design/icons';
import moment from 'moment';
import { HealthLevel } from '../../../graphql/query/locationHealthSummaries';
import { HealthState } from '../../../graphql/query/getMarketplaceMetrics';
import { copyTextToClipboard } from '../../../utils/clipboard.utils';
import { SuperAdmin, useSuperAdminsQuery } from '../../../graphql/query/superAdmins';
import { TOGGLE_DISMISS_HEALTH_REASON } from '../../../graphql/mutations/toggleDismissHealthReason';
const { Title, Text } = Typography;
const { Content } = Layout;
const { Panel } = Collapse;

const RED = '#FDE8E8';
const YELLOW = '#FFFBE6';
const GREEN = '#E8F8E8';
const GRAY = '#f5f5f5';

type LocationHealthSummaryResponse = {
  locationHealthSummary: LocationHealthSummary;
};

interface PointOfContact {
  name: string;
  email: string;
  phone: string;
}

export interface HealthReason {
  id: string;
  severity: string;
  type: string;
  name: string;
  description: string;
  dismissible: boolean;
  dismissedAt: string;
}

type PostingUser = {
  id: string;
  name: string;
  token: string;
  profileId?: string;
  lastPostAt?: string;
  healthStatus?: {
    health: string;
    reasons: string[];
    updatedAt: string;
  };
  jobs: {
    id: string;
    jobType: string;
    createdAt: string;
    status?: string;
    failedReason?: string;
    htmlScreenshotUrl?: string;
    extensionVersion?: string;
    userAgent?: string;
    currentStep?: string;
  }[];
};

type LocationHealthSummary = {
  organizationName: string;
  locationName: string;
  poc?: PointOfContact;
  healthLevel: string;
  lastRefreshedAt: string;
  snoozedUntil?: string | null;
  notes?: string | null;
  healthReasons: HealthReason[];
  postingUsers: PostingUser[];
  assignedUser?: SuperAdmin;
  dismissedAt?: string | number | Date | null;
};

// GraphQL Query
const LOCATION_HEALTH_SUMMARY = gql`
  query locationHealthSummary($locationId: String!) {
    locationHealthSummary(locationId: $locationId) {
      organizationName
      locationName
      assignedUser {
        id
        fullName
        username
      }
      poc {
        name
        email
        phone
      }
      healthLevel
      lastRefreshedAt
      snoozedUntil
      dismissedAt
      notes
      healthReasons {
        id
        severity
        type
        name
        description
        dismissible
        dismissedAt
      }
      postingUsers {
        id
        name
        token
        profileId
        lastPostAt
        jobs {
          id
          createdAt
          jobType
          status
          failedReason
          htmlScreenshotUrl
          extensionVersion
          userAgent
          currentStep
        }
        healthStatus {
          health
          reasons
          updatedAt
        }
      }
    }
  }
`;

const LocationHealthSummary: React.FC = () => {
  const { locationId } = useParams<{ locationId: string }>();
  const { data, loading, error } = useQuery<LocationHealthSummaryResponse>(LOCATION_HEALTH_SUMMARY, {
    variables: { locationId },
  });
  const [toggleDismissHealthReason] = useMutation(TOGGLE_DISMISS_HEALTH_REASON, {
    refetchQueries: [LOCATION_HEALTH_SUMMARY],
  });
  const [refreshLocationHealthSummary] = useMutation(REFRESH_LOCATION_HEALTH_SUMMARY, {
    refetchQueries: [LOCATION_HEALTH_SUMMARY],
  });
  const { superAdmins } = useSuperAdminsQuery();

  const superAdminOptions = superAdmins.map((superAdmin) => ({
    label: superAdmin.fullName || superAdmin.username,
    value: superAdmin.id,
  }));

  const [updateLocationHealthSummary, { loading: savingLocationSummary }] = useMutation(
    UPDATE_LOCATION_HEALTH_SUMMARY,
    {
      refetchQueries: [LOCATION_HEALTH_SUMMARY],
    }
  );

  const onRefreshHealthSummary = useCallback(async () => {
    try {
      await refreshLocationHealthSummary({ variables: { locationId } });
    } catch (error) {
      Sentry.captureException(error);
      displayGqlErrors(error, `Unable to update token status`);
    }
  }, [locationId, refreshLocationHealthSummary]);

  const toggleDismissReason = useCallback(
    async (id: string) => {
      try {
        await toggleDismissHealthReason({
          variables: {
            id,
          },
        });
      } catch (error) {
        Sentry.captureException(error);
        displayGqlErrors(error, `Unable to dismiss`);
      }
    },
    [toggleDismissHealthReason]
  );

  const onSaveNotes = useCallback(
    async (content: string) => {
      try {
        await updateLocationHealthSummary({
          variables: {
            input: {
              locationId,
              notes: content,
            },
          },
        });
      } catch (error) {
        Sentry.captureException(error);
        displayGqlErrors(error, `Unable to update token status`);
      }
    },
    [locationId, updateLocationHealthSummary]
  );

  const onAssignUser = useCallback(
    async (assignedUserId: string | undefined) => {
      try {
        await updateLocationHealthSummary({
          variables: {
            input: {
              locationId,
              assignedUserId: assignedUserId || null,
            },
          },
        });

        message.info('Assigned to updated successfully');
      } catch (error) {
        Sentry.captureException(error);
        displayGqlErrors(error, `Unable to update assigned to`);
      }
    },
    [locationId, updateLocationHealthSummary]
  );

  const onConfirmSnooze = useCallback(async () => {
    try {
      await updateLocationHealthSummary({
        variables: {
          input: {
            locationId,
            snoozedUntil: moment().add(3, 'days').toISOString(),
          },
        },
      });
    } catch (e) {
      Sentry.captureException(error);
      displayGqlErrors(error, `Unable to update token status`);
    }
  }, [locationId, updateLocationHealthSummary]);

  const onSnoozeClick = useCallback(async () => {
    confirm({
      title: 'Are you sure you want to snooze this location?',
      icon: <ExclamationCircleOutlined />,
      okText: `Yes`,
      content: 'Snoozing a location will hide it from the dashboard for 3 days.',
      onOk() {
        onConfirmSnooze();
      },
    });
  }, [onConfirmSnooze]);

  const onConfirmDismiss = useCallback(async () => {
    try {
      await updateLocationHealthSummary({
        variables: {
          input: {
            locationId,
            dismissedAt: data?.locationHealthSummary?.dismissedAt ? null : new Date().toISOString(),
          },
        },
      });
    } catch (e) {
      Sentry.captureException(error);
      displayGqlErrors(error, `Unable to dismiss location`);
    }
  }, [locationId, updateLocationHealthSummary, data?.locationHealthSummary?.dismissedAt]);

  const onDismissClick = useCallback(async () => {
    confirm({
      title: data?.locationHealthSummary?.dismissedAt
        ? 'Are you sure you want to un-dismiss this location?'
        : 'Are you sure you want to dismiss this location?',
      icon: <ExclamationCircleOutlined />,
      okText: `Yes`,
      content: data?.locationHealthSummary?.dismissedAt
        ? 'Un-dismissing this location will show it on the dashboard again.'
        : 'Dismissing a location will hide it from the dashboard.',
      onOk() {
        onConfirmDismiss();
      },
    });
  }, [onConfirmDismiss, data?.locationHealthSummary?.dismissedAt]);

  if (
    !data?.locationHealthSummary?.organizationName ||
    !data.locationHealthSummary.locationName ||
    !data.locationHealthSummary.healthLevel ||
    !data.locationHealthSummary.lastRefreshedAt
  ) {
    return (
      <div style={{ textAlign: 'center', padding: '20px' }}>
        <Text type="danger">Missing required location data</Text>
      </div>
    );
  }

  const {
    organizationName,
    locationName,
    poc,
    healthLevel,
    lastRefreshedAt,
    notes,
    healthReasons = [],
    postingUsers = [],
    snoozedUntil,
    assignedUser,
    dismissedAt,
  } = data.locationHealthSummary;

  if (loading) {
    return (
      <div style={{ textAlign: 'center', padding: '20px' }}>
        <Spin size="large" />
      </div>
    );
  }

  if (!data) {
    return (
      <Button style={{ textAlign: 'center', margin: '20px' }} type="primary" onClick={onRefreshHealthSummary}>
        No health summary found. Click to create.
      </Button>
    );
  }

  if (error) {
    return (
      <div style={{ textAlign: 'center', padding: '20px' }}>
        <Text type="danger">Error loading data</Text>
      </div>
    );
  }

  if (!data?.locationHealthSummary) {
    return (
      <div style={{ textAlign: 'center', padding: '20px' }}>
        <Text type="danger">No data available</Text>
      </div>
    );
  }

  return (
    <Layout style={{ minHeight: '100vh', backgroundColor: '#fff' }}>
      <Content>
        <LocationCard
          {...{
            organizationName,
            locationName,
            poc,
            healthLevel,
            lastRefreshedAt,
            onRefreshClick: onRefreshHealthSummary,
            onSnoozeClick: onSnoozeClick,
            onDismissClick: onDismissClick,
            snoozedUntil,
            dismissedAt,
            superAdminOptions,
            savingLocationSummary,
            onAssignUser,
            assignedUserId: assignedUser?.id,
          }}
        />
        <PageSection>
          <Title level={4} style={{ textAlign: 'left', color: 'rgb(74, 79, 137)' }}>
            Notes
          </Title>
          <NotesSection style={{ width: '100%' }} initialValue={notes ?? undefined} onSave={onSaveNotes} />
        </PageSection>
        <HealthReasonsSection healthReasons={healthReasons} toggleDismiss={toggleDismissReason} />
        <Divider />
        <PostingUsersSection users={postingUsers} />
      </Content>
    </Layout>
  );
};

interface LocationCardProps {
  organizationName: string;
  locationName: string;
  poc?: PointOfContact;
  healthLevel: string;
  lastRefreshedAt: string | number | Date;
  snoozedUntil?: string | number | Date | null;
  dismissedAt?: string | number | Date | null;
  onRefreshClick: () => void;
  onSnoozeClick: () => void;
  onDismissClick: () => void;
  superAdminOptions: { label: string; value: string }[];
  assignedUserId?: string;
  savingLocationSummary: boolean;
  onAssignUser: (assignedUserId: string | undefined) => void;
}

const healthColorMap: Record<string, string> = {
  red: 'red',
  yellow: 'gold',
  green: 'green',
};

const LocationCard: React.FC<LocationCardProps> = ({
  organizationName,
  locationName,
  poc,
  healthLevel,
  lastRefreshedAt,
  snoozedUntil,
  dismissedAt,
  onRefreshClick,
  onSnoozeClick,
  onDismissClick,
  superAdminOptions,
  assignedUserId,
  savingLocationSummary,
  onAssignUser,
}) => {
  return (
    <PageSection
      title={
        <Row justify="space-between" align="middle" style={{ textAlign: 'left' }}>
          <div>
            <Title level={4} style={{ marginBottom: 0, color: 'rgb(74, 79, 137)' }}>
              {locationName} <Tag color={healthColorMap[healthLevel.toLowerCase()] || 'default'}>{healthLevel}</Tag>
            </Title>
            <Text style={{ fontSize: '12px', color: 'rgba(0, 0, 0, 0.45)' }}>
              Last Refreshed: {new Date(lastRefreshedAt).toLocaleString()}
            </Text>
            <br></br>
            {snoozedUntil && (
              <Text style={{ fontSize: '12px', color: 'rgba(0, 0, 0, 0.45)' }}>
                Snoozed Until: {new Date(snoozedUntil).toLocaleString()}
              </Text>
            )}
            {dismissedAt && (
              <Text style={{ fontSize: '12px', color: 'rgba(0, 0, 0, 0.45)' }}>
                Dismissed At: {new Date(dismissedAt).toLocaleString()}
              </Text>
            )}
          </div>
          <Row gutter={8} align="middle">
            <Select
              placeholder={'Assign to User'}
              options={superAdminOptions}
              defaultValue={assignedUserId}
              loading={savingLocationSummary}
              onChange={(value) => onAssignUser(value)}
              allowClear
            />
            <Tooltip title="Snooze Location">
              <Button onClick={onSnoozeClick} type="link" icon={<FaRegClock />} />
            </Tooltip>
            <Tooltip title="Refresh Health">
              <Button onClick={onRefreshClick} type="link" icon={<FaSyncAlt />} />
            </Tooltip>
            <Tooltip title="Dismiss Location">
              <Button
                onClick={onDismissClick}
                type="link"
                icon={<FaRegTimesCircle />}
                style={{ color: dismissedAt ? 'rgba(0, 0, 0, 0.65)' : '#ff4d4f' }}
              />
            </Tooltip>
          </Row>
        </Row>
      }
      headStyle={{
        backgroundColor: 'rgba(74, 79, 137, 0.1)',
        borderBottom: 'none',
        borderRadius: '12px 12px 0 0',
      }}
    >
      <Row gutter={[16, 24]} style={{ textAlign: 'left' }}>
        <Col span={24}>
          <Text strong>Organization Name:</Text> <Text>{organizationName}</Text>
        </Col>
        {poc && (
          <Col span={24}>
            <Text strong>Point of Contact:</Text>
            <div
              style={{
                marginTop: '8px',
                padding: '8px',
                background: 'rgba(74, 79, 137, 0.05)',
                borderRadius: '8px',
              }}
            >
              <Text>{poc.name}</Text>
              <br />
              <Text>{poc.email}</Text>
              <br />
              {poc.phone && <Text>{poc.phone}</Text>}
            </div>
          </Col>
        )}
      </Row>
    </PageSection>
  );
};

interface HealthReasonsSectionProps {
  healthReasons: HealthReason[];
  toggleDismiss: (id: string) => void; // Function to handle dismissing a health reason
}

const PageSection: React.FC<{
  children: React.ReactNode;
  title?: React.ReactNode;
  headStyle?: React.CSSProperties;
}> = ({ children, title, headStyle }) => {
  return (
    <Content style={{ padding: '24px', paddingBottom: '0px', width: '100%' }}>
      <Card
        style={{
          backgroundColor: '#fff',
          borderColor: 'rgb(74, 79, 137)',
          borderRadius: '12px',
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
          marginBottom: '0px',
        }}
        title={title}
        headStyle={headStyle}
      >
        {children}
      </Card>
    </Content>
  );
};

function getBackgroundColorForUser(health?: string) {
  if (!health) {
    return GRAY;
  }

  if (health === HealthState.Great) {
    return GREEN;
  } else if (health === HealthState.AbleToPost) {
    return YELLOW;
  } else if (health === HealthState.UnableToPost) {
    return RED;
  }
}

function getBackgroundColor({ severity, dismissedAt }: HealthReason) {
  if (dismissedAt) {
    return GRAY;
  }

  if (severity === HealthLevel.Red) {
    return RED;
  } else if (severity === HealthLevel.Yellow) {
    return YELLOW;
  } else {
    return GREEN;
  }
}

const HealthReasonsSection: React.FC<HealthReasonsSectionProps> = ({ healthReasons, toggleDismiss }) => {
  return (
    <PageSection>
      <Title level={4} style={{ textAlign: 'left', color: 'rgb(74, 79, 137)' }}>
        Health Reasons
      </Title>
      <Row gutter={[8, 8]}>
        {healthReasons.map((reason) => (
          <Col span={6} key={reason.id}>
            <Tooltip title={reason.description}>
              <Card
                key={reason.id}
                bodyStyle={{ padding: '8px' }}
                style={{
                  marginBottom: '8px',
                  borderColor: 'rgb(74, 79, 137)',
                  borderRadius: '12px',
                  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                  width: '100%',
                  backgroundColor: getBackgroundColor(reason),
                }}
              >
                <Row justify="space-between" align="middle">
                  <Col>
                    {reason.dismissedAt ? (
                      <>
                        <Text delete strong>
                          {reason.name}
                        </Text>
                        <Text style={{ paddingLeft: '8px' }} strong>
                          DISMISSED
                        </Text>
                      </>
                    ) : (
                      <Text strong>{reason.name}</Text>
                    )}
                  </Col>
                  {reason.dismissible && (
                    <Col>
                      <Button
                        type="link"
                        onClick={() => toggleDismiss(reason.id)}
                        style={{ fontWeight: 'bold', color: 'rgba(0, 0, 0, 0.65)' }}
                      >
                        {Boolean(reason.dismissedAt) ? 'Undo Dismiss' : 'Dismiss'}
                      </Button>
                    </Col>
                  )}
                </Row>
                <Row>
                  <Text
                    style={{
                      maxWidth: '70%',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      display: 'inline-block',
                    }}
                  >
                    {reason.description}
                  </Text>
                </Row>
              </Card>
            </Tooltip>
          </Col>
        ))}
      </Row>
    </PageSection>
  );
};

type PostingUsersSectionProps = { users: PostingUser[] };
const PostingUsersSection: React.FC<PostingUsersSectionProps> = ({ users }) => {
  return (
    <PageSection>
      <Title level={4} style={{ textAlign: 'left', color: 'rgb(74, 79, 137)' }}>
        Posting Users
      </Title>
      <Row gutter={[16, 4]}>
        {users.map((user) => {
          return (
            <Col xs={24} sm={12} lg={8} key={user.id}>
              <Card
                title={
                  <>
                    <Typography.Title level={5} style={{ textAlign: 'left' }}>
                      <strong>{user.name}</strong>;
                    </Typography.Title>
                    <Typography.Paragraph style={{ textAlign: 'left' }}>
                      <strong>{user.token}</strong>
                    </Typography.Paragraph>
                  </>
                }
                style={{
                  backgroundColor: getBackgroundColorForUser(user.healthStatus?.health),
                  marginBottom: 16,
                  borderColor: 'rgb(74, 79, 137)',
                  borderRadius: '12px',
                  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                }}
              >
                {user.healthStatus && (
                  <div style={{ marginBottom: 16, textAlign: 'left' }}>
                    {user.profileId && (
                      <>
                        <Typography.Text>
                          <a target="_blank" href={`https://www.facebook.com/marketplace/profile/${user.profileId}`}>
                            View Profile
                          </a>
                        </Typography.Text>
                        <br />
                      </>
                    )}
                    <Typography.Text>
                      <strong>Last Post At:</strong>{' '}
                      {user.lastPostAt ? new Date(user.lastPostAt).toLocaleString() : 'Never'}
                    </Typography.Text>
                    <br />
                    <Typography.Text>
                      <strong>Health:</strong> {user.healthStatus.health}
                    </Typography.Text>
                    <br />
                    <Typography.Text>
                      <strong>Reasons:</strong>
                      <div>
                        {user.healthStatus.reasons.map((reason) => {
                          return (
                            <Tag key={reason} color="">
                              {reason}
                            </Tag>
                          );
                        })}
                      </div>
                    </Typography.Text>
                    <br />
                    <Typography.Text>
                      <strong>Updated At:</strong> {new Date(user.healthStatus.updatedAt).toLocaleString()}
                    </Typography.Text>
                  </div>
                )}
                <Collapse expandIcon={({ isActive }) => (isActive ? <UpOutlined /> : <DownOutlined />)}>
                  <Panel header="Jobs" key="1">
                    <List
                      dataSource={user.jobs}
                      renderItem={(job) => (
                        <List.Item style={{ textAlign: 'left' }}>
                          <List.Item.Meta
                            title={job.jobType}
                            description={
                              <>
                                <Typography.Text>
                                  <strong>Status:</strong> {job.status || 'N/A'}
                                </Typography.Text>
                                <br />
                                <Typography.Text>
                                  <strong>Date:</strong> {new Date(job.createdAt).toLocaleString()}
                                </Typography.Text>
                                <br />
                                {job.failedReason && (
                                  <>
                                    <Typography.Text>
                                      <strong>Failed Reason:</strong> {job.failedReason}
                                    </Typography.Text>
                                    <br />
                                  </>
                                )}
                                {job.currentStep && (
                                  <>
                                    <Typography.Text>
                                      <strong>Current Step:</strong> {job.currentStep}
                                    </Typography.Text>
                                    <br />
                                  </>
                                )}
                                {job.extensionVersion && (
                                  <>
                                    <Typography.Text>
                                      <strong>Extension Version:</strong> {job.extensionVersion}
                                    </Typography.Text>
                                    <br />
                                  </>
                                )}
                                {job.htmlScreenshotUrl && (
                                  <Button
                                    type="link"
                                    style={{ padding: 0 }}
                                    onClick={() => {
                                      copyTextToClipboard(job.htmlScreenshotUrl!);
                                      message.info('Link copied to clipboard');
                                    }}
                                  >
                                    Copy Screenshot to Clipboard
                                  </Button>
                                )}
                              </>
                            }
                          />
                        </List.Item>
                      )}
                    />
                  </Panel>
                </Collapse>
              </Card>
            </Col>
          );
        })}
      </Row>
    </PageSection>
  );
};

export default LocationHealthSummary;
