import moment from 'moment';
import { PostTypeEnum } from '../../../graphql/query/postHistoryByLocationId';
import { useQueryParam, ObjectParam, ArrayParam, withDefault } from 'use-query-params';

export interface DashboardFilter {
  organizationIds: string[];
  setOrganizationIds: (orgId: string[]) => void;
  setLocationIds: (locId: string[]) => void;
  locationIds: string[];
  postTypes: PostTypeEnum[];
  setPostTypes: (types: PostTypeEnum[]) => void;
  dateRange: DateRange;
  setDateRange: (date: DateRange) => void;
}

export interface DateRange {
  startDate: Date;
  endDate: Date;
}

export function useDashboardFilters(): DashboardFilter {
  const [organizationIds, setOrganizationIds] = useQueryParam('organizationIds', ArrayParam);
  const [locationIds, setLocationIds] = useQueryParam('locationIds', ArrayParam);
  const [postTypes, setPostTypes] = useQueryParam(
    'postTypes',
    withDefault(ArrayParam, [PostTypeEnum.OFFER, PostTypeEnum.VEHICLE])
  );
  const [dateRange, setDateRange] = useQueryParam(
    'dateRange',
    //@ts-ignore
    withDefault(ObjectParam, {
      startDate: moment(0).toDate(),
      endDate: moment().toDate(),
    })
  );

  return {
    organizationIds: (organizationIds ?? []) as string[],
    setOrganizationIds,
    locationIds: (locationIds ?? []) as string[],
    setLocationIds,
    postTypes: (postTypes ?? []) as PostTypeEnum[],
    setPostTypes,
    //@ts-ignore
    dateRange,
    //@ts-ignore
    setDateRange,
  };
}
