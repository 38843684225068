import React, { useEffect, useState } from 'react';
import { Select, Input, Button } from 'antd';
import { SimpleCardStyled } from './AccountInformationCard';
import { Spacer } from '../Spacer';
import { LabelBodyB } from '../Text/Text.styled';
import { snackbar } from '../Snackbar';

const { TextArea } = Input;

const DEFAULT_MARKETPLACE_VARIABLES = [
  'vin',
  'stock',
  'body',
  'trim',
  'make',
  'model',
  'modelNumber',
  'engine',
  'driveType',
  'transmission',
  'extColor',
  'intColor',
];

const variableDisplayMap: Record<string, string> = {
  vin: 'VIN: @vin',
  stock: 'Stock: @stock',
  condition: 'Condition: @condition',
  price: 'Price: @price',
  model: 'Model: @model',
  body: 'Body: @body',
  trim: 'Trim: @trim',
  transmission: 'Transmission: @transmission',
  driveType: 'Drive Type: @driveType',
  intColor: 'Interior Color: @intColor',
  extColor: 'Exterior Color: @extColor',
  engine: 'Engine: @engine',
  engineHours: 'Engine Hours: @engineHours',
  year: 'Year: @year',
  mileage: 'Mileage: @mileage',
  make: 'Make: @make',
  modelNumber: 'Model Number: @modelNumber',
  address: 'Address: @address',
  dealershipNumber: 'Dealership Number: @dealershipNumber',
  locationName: 'Location Name: @locationName',
  vehDescription: 'Description: @vehDescription',
};

export const defaultMarketplaceTemplate = () => {
  return DEFAULT_MARKETPLACE_VARIABLES.map((variable) => variableDisplayMap[variable]).join('\n');
};

const variableValues: Record<string, string> = {
  vin: '1HGCM82633A123456',
  stock: 'A1234',
  condition: 'New',
  price: '$20,000',
  model: 'Accord',
  body: 'Sedan',
  trim: 'EX',
  transmission: 'Automatic',
  driveType: 'FWD',
  intColor: 'Black',
  extColor: 'White',
  engine: '2.0L I4',
  engineHours: '120',
  year: '2023',
  mileage: '0',
  make: 'Honda',
  modelNumber: 'AC123',
  address: '123 Main St, Springfield',
  dealershipNumber: '12345',
  locationName: 'Springfield Honda',
  vehDescription: 'This is a new Honda Accord. It has a 2.0L I4 engine and 120 engine hours.',
};

const PostDescriptionBuilder: React.FC<{
  template?: string;
  header?: string;
  footer?: string;
  handleSave: ({ template, header, footer }: { template: string; header: string; footer: string }) => void;
}> = ({ template: initialTemplate, header: initialHeader, footer: initialFooter, handleSave }) => {
  const parseTemplateVariables = (template: string): string[] => {
    const matches = template.match(/@(\w+)/g);
    return matches ? matches.map((match) => match.slice(1)) : [];
  };

  const [selectedVariables, setSelectedVariables] = useState<string[]>(DEFAULT_MARKETPLACE_VARIABLES);
  const [footer, setFooter] = useState<string>(initialFooter || '');
  const [header, setHeader] = useState<string>(initialHeader || '');
  const [template, setTemplate] = useState<string>(initialTemplate || defaultMarketplaceTemplate());

  useEffect(() => {
    setHeader(initialHeader || '');
    setFooter(initialFooter || '');

    if (initialTemplate) {
      setTemplate(initialTemplate);
      setSelectedVariables(parseTemplateVariables(initialTemplate));
    } else {
      const defaultTemplate = DEFAULT_MARKETPLACE_VARIABLES.map((variable) => variableDisplayMap[variable]).join('\n');
      setTemplate(defaultTemplate);
      setSelectedVariables(DEFAULT_MARKETPLACE_VARIABLES);
    }
  }, [initialTemplate, initialFooter, initialHeader]);

  const handleVariableChange = (value: string[]) => {
    if (!value.includes('vin')) {
      value.unshift('vin');
      snackbar.warning({ message: 'VIN is required in the template' });
    }

    setSelectedVariables(value);
    const variableTemplate = value.map((variable) => variableDisplayMap[variable]).join('\n');
    setTemplate(variableTemplate);
  };

  const handleFooterChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setFooter(e.target.value);
  };

  const handleHeaderChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setHeader(e.target.value);
  };

  const getPreview = () => {
    let preview = template;
    selectedVariables.forEach((variable) => {
      const regex = new RegExp(`@${variable}`, 'g');
      preview = preview.replace(regex, variableValues[variable] || `(${variable})`);
    });

    const headerLine = header ? `${header}\n\n` : '';

    return `${headerLine}${preview}\n\n${footer}`.trim();
  };

  return (
    <SimpleCardStyled style={{ width: '100%' }} topHeader="Custom Marketplace Descriptions">
      <div style={{ padding: '24px', display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
        {/* Left Section */}
        <div style={{ width: '48%' }}>
          <LabelBodyB>Description Header</LabelBodyB>
          <TextArea
            rows={4}
            placeholder="This will show up at the top of all your posts"
            value={header}
            onChange={handleHeaderChange}
          />
          <Spacer type="stack" size="s" />
          <LabelBodyB>Select Attributes</LabelBodyB>
          <Select
            mode="multiple"
            style={{ width: '100%' }}
            placeholder="Select Attributes"
            value={selectedVariables}
            onChange={handleVariableChange}
            options={Object.keys(variableDisplayMap).map((key) => ({
              label: key,
              value: key,
            }))}
          />
          <Spacer type="stack" size="s" />
          <LabelBodyB>Description Footer</LabelBodyB>
          <TextArea
            rows={4}
            placeholder="This will show up at the bottom of all your posts"
            value={footer}
            onChange={handleFooterChange}
          />
        </div>

        {/* Right Section */}
        <div style={{ width: '48%' }}>
          <LabelBodyB>Preview</LabelBodyB>
          <div
            style={{
              border: '1px solid #d9d9d9',
              padding: '8px',
              whiteSpace: 'pre-wrap',
              height: '90%',
              textAlign: 'left',
              wordWrap: 'break-word',
            }}
          >
            {getPreview() || 'Preview will appear here...'}
          </div>
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '16px' }}>
        <Button type="primary" onClick={() => handleSave({ template, header, footer })}>
          SAVE DESCRIPTION{' '}
        </Button>
      </div>
      <Spacer type="stack" size="m" />
    </SimpleCardStyled>
  );
};

export default PostDescriptionBuilder;
