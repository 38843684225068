export const initializePendo = ({ userId, organizationId }: { userId: string; organizationId: string }) => {
  //@ts-ignore
  const pendo = window.pendo || null;
  console.log(pendo);
  if (pendo) {
    console.log(process.env.REACT_APP_PENDO_API_KEY);
    pendo.initialize({
      apiKey: process.env.REACT_APP_PENDO_API_KEY,
      visitor: { id: userId },
      account: { id: organizationId },
    });
  }
};
