import React, { useRef } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import * as Sentry from '@sentry/react';
import styled from 'styled-components';
import {
  EDIT_ORGANIZATION,
  EditOrganizationParams,
  EditOrganizationRequest,
  EditOrganizationResponse,
} from '../../../../graphql/mutations/editOrganization';
import { OrganizationItem } from '../../../../graphql/query/organizationList';
import { FormRef } from '../../../../models/form';
import SideDrawer from '../../SideDrawer';
import { snackbar } from '../../Snackbar';
import { LabelBodyB } from '../../Text/Text.styled';
import EditOrganizationForm, { EditOrganizationFormValue } from './EditOrganizationForm';
import {
  USERS_BY_ORG_ID,
  UsersByOrgIdParams,
  UsersByOrgIdResponse,
} from '../../../../graphql/query/usersByOrganizationId';
import { useParams } from 'react-router-dom';
import { SelectOption } from '../../FormSelectField/FormSelectField';
import { Partner, usePartnerQuery } from '../../../../graphql/query/partners';

interface EditOrganizationDrawerProps {
  isSideDrawerVisible: boolean;
  toggleSideDrawer: (value?: boolean) => void;
  updateObject: OrganizationItem;
}

const FormContainer = styled.section`
  margin: 16px;
  width: 100%;
`;
const SectionTitleContainer = styled.div`
  margin-bottom: 16px;
`;

const EditOrganizationDrawer: React.FC<EditOrganizationDrawerProps> = ({
  isSideDrawerVisible,
  toggleSideDrawer,
  updateObject,
}) => {
  const formRef = useRef<FormRef<EditOrganizationFormValue>>(null);
  const initialValues: EditOrganizationFormValue = {
    avatarId: updateObject.avatar?.id,
    avatarImg: updateObject.avatar?.signedUrl,
    name: updateObject.name,
    website: updateObject.domain,
    address: updateObject.headquarters,
    city: updateObject.city,
    state: updateObject.state,
    country: updateObject.country,
    pocUserId: updateObject?.pocUserId,
    partnerId: updateObject?.partner?.id,
  };

  const [editOrganization] = useMutation<EditOrganizationResponse, EditOrganizationParams>(EDIT_ORGANIZATION);
  const { organizationId } = useParams<{ organizationId: string }>();
  const { data } = useQuery<UsersByOrgIdResponse, UsersByOrgIdParams>(USERS_BY_ORG_ID, {
    variables: {
      organizationId: organizationId,
      onlyActive: true,
    },
  });
  const users = data?.usersByOrgId ?? [];

  const { partners } = usePartnerQuery();

  const userOptions = users.map<SelectOption>((user) => ({
    label: user.fullName ?? '',
    value: user.id,
  }));

  const partnerOptions = partners?.map<SelectOption>((partner: Partner) => ({
    label: partner.name,
    value: partner.id,
  }));

  const handleUpdateOrganization = async (formData: EditOrganizationFormValue) => {
    try {
      const organization: EditOrganizationRequest = {
        ...formData,
        id: updateObject.id,
      };
      await editOrganization({
        variables: { data: organization },
      });
      snackbar.success({
        message: 'Organization updated Successfully',
      });
      toggleSideDrawer();
    } catch (error) {
      Sentry.captureException(error);
      snackbar.error({
        message: 'Unable to update organization',
      });
    }
  };
  return (
    <SideDrawer
      heading="Organization"
      isDrawerVisible={isSideDrawerVisible}
      toggleDrawerVisible={toggleSideDrawer}
      ctaButtons={[
        {
          label: 'EDIT ORGANIZATION',
          onClick: async () => {
            formRef.current?.submit();
          },
          isDisabled: false,
        },
      ]}
    >
      <FormContainer>
        <SectionTitleContainer>
          <LabelBodyB>Organization Details</LabelBodyB>
        </SectionTitleContainer>
        <EditOrganizationForm
          ref={formRef}
          onCompleted={handleUpdateOrganization}
          initialValues={initialValues}
          userOptions={userOptions}
          partnerOptions={partnerOptions}
        />
      </FormContainer>
    </SideDrawer>
  );
};

export default EditOrganizationDrawer;
