import React, { useState } from 'react';

import { DownOutlined } from '@ant-design/icons';
import { ApolloError, useMutation } from '@apollo/client';
import * as Sentry from '@sentry/react';

import { GqlMutationError } from '../../../../graphql/gql-error';
import {
  PRIORITIZE_ON_MARKETPLACE,
  PrioritizeOnMarketplaceParams,
} from '../../../../graphql/mutations/prioritizeOnMarketplace';
import { GetLocationAccountInfoResponse } from '../../../../graphql/query/getLocationAccountsInfoInfo';
import { InventoryItem, Status } from '../../../../graphql/query/inventoryByLocationId';
import { LocationProductEnum } from '../../../../graphql/query/locationsByOrgId';
import { saveUrlsToZip } from '../../../../utils/file.utils';
import { hasProduct } from '../../../utils/product.utils';
import { Button } from '../../Buttons/Button';
import { CreatePostModal } from '../../CreatePostModal';
import { DefaultModal } from '../../DefaultModal';
import { displayGqlErrors } from '../../ErrorList';
import { MenuCompact, MenuOption } from '../../MenuCompact';
import { snackbar } from '../../Snackbar';
import { Spacer } from '../../Spacer';
import { VideoPostModal } from '../../VideoPostModal';
import {
  MARK_DELETED_ON_MARKETPLACE,
  MarkDeletedOnMarketplaceParams,
} from '../../../../graphql/mutations/markDeletedOnMarketplace';
import { Permission, useHasPermission } from '../../../../hooks/useHasPermission';
import useToggleCanListToMarketplace from '../../../../graphql/mutations/toggleCanListOnMarketplace';

export const InventoryActionButtons: React.FC<{
  vehicle: InventoryItem;
  vehiclesSelected: string[];
  locationAccountsInfo: GetLocationAccountInfoResponse;
  setItemsSelected: React.Dispatch<React.SetStateAction<string[]>>;
}> = ({ setItemsSelected, vehicle, locationAccountsInfo, vehiclesSelected }) => {
  const [downloadingImages, setDownloadingImages] = useState(false);
  const [showPostModal, setShowPostModal] = useState(false);
  const [showVideoPostModal, setShowVideoPostModal] = useState(false);
  const [prioritizeOnMarketplace] = useMutation<any, PrioritizeOnMarketplaceParams>(PRIORITIZE_ON_MARKETPLACE);
  const [prioritize, setPrioritize] = useState(vehicle.marketplacePriority);
  const { toggleCanListToMarketplace, canListToMarketplace } = useToggleCanListToMarketplace(
    vehicle.id,
    vehicle.canListToMarketplace
  );

  const [markDeletedOnMarketplace] = useMutation<boolean>(MARK_DELETED_ON_MARKETPLACE);

  const onClickPost = (item: InventoryItem) => {
    setShowPostModal(true);
    setItemsSelected((prv: string[]) => [...prv, item.id]);
  };

  const onClickVidePost = (item: InventoryItem) => {
    setShowVideoPostModal(true);
    setItemsSelected((prv: string[]) => [...prv, item.id]);
  };

  const onDownloadImages = async (item: InventoryItem) => {
    try {
      setDownloadingImages(true);
      const zipFileName = `${item.make} ${item.model} - ${item.vin}`;
      await saveUrlsToZip(zipFileName, item.images);
    } finally {
      setDownloadingImages(false);
    }
  };

  const onClickMarkDeletedOnMarketplace = async () => {
    try {
      const requestData: MarkDeletedOnMarketplaceParams = {
        vin: vehicle.vin,
        locationId: locationAccountsInfo.location?.id,
      };
      await markDeletedOnMarketplace({
        variables: requestData,
      });

      snackbar.success({
        message: `Vehicle was set to be deleted on Marketplace`,
      });
    } catch (error) {
      Sentry.captureException(error);
      displayGqlErrors(error as ApolloError | GqlMutationError, `Unable to set vehicle to be deleted on Marketplace`);
    }
  };

  const actionLabel = !prioritize ? 'Prioritize on Marketplace' : 'Remove Marketplace Priority';
  const canListToMarketplaceLabel = canListToMarketplace ? "Don't post to Marketplace" : 'Post to Marketplace';
  const onPrioritizeOnMarketplace = async () => {
    try {
      const requestData: PrioritizeOnMarketplaceParams = {
        inventoryId: vehicle.id,
        marketplacePriority: !vehicle.marketplacePriority,
      };
      await prioritizeOnMarketplace({
        variables: requestData,
      });
      setPrioritize(requestData.marketplacePriority);
      snackbar.success({
        message: `Vehicle was ${actionLabel} Successfully`,
      });
    } catch (error) {
      Sentry.captureException(error);
      displayGqlErrors(error as ApolloError | GqlMutationError, `Unable to ${actionLabel}`);
    }
  };

  const canUseInventoryActions = useHasPermission(Permission.CanUseInventoryActions);

  let extraActions: MenuOption[] = [];
  if (canUseInventoryActions) {
    extraActions = [
      {
        label: 'Post',
        active: vehicle.status !== Status.SOLD,
        onClick: () => {
          onClickPost(vehicle);
        },
      },
      {
        label: 'Post Video',
        active: vehicle.status !== Status.SOLD,
        onClick: () => {
          onClickVidePost(vehicle);
        },
      },
      {
        label: `${downloadingImages ? 'Saving images' : ' Download'}`,
        active: !downloadingImages,
        onClick: () => {
          onDownloadImages(vehicle);
        },
      },
      {
        label: 'Delete Marketplace Post',
        active: true,
        onClick: () => {
          onClickMarkDeletedOnMarketplace();
        },
      },
    ];
  }

  const [showOptions, setShowOptions] = useState(false);
  return (
    <React.Fragment>
      <MenuCompact
        setShowOptions={setShowOptions}
        visible={showOptions}
        options={[
          ...extraActions,
          {
            label: actionLabel,
            active: hasProduct(locationAccountsInfo.location?.products ?? [], LocationProductEnum.FbMarketplace),
            onClick: () => {
              onPrioritizeOnMarketplace();
            },
          },
          {
            label: canListToMarketplaceLabel,
            active: hasProduct(locationAccountsInfo.location?.products ?? [], LocationProductEnum.FbMarketplace),
            onClick: () => {
              toggleCanListToMarketplace();
            },
          },
        ]}
      >
        <Button
          btnType={vehiclesSelected.includes(vehicle.id) ? 'selected' : 'primary'}
          onClick={() => setShowOptions(true)}
          size="small"
        >
          Actions
          <Spacer type="inline" size="xxs" />
          <DownOutlined size={10} />
        </Button>
      </MenuCompact>
      <DefaultModal
        visible={showPostModal}
        width={1380}
        onCancel={() => setShowPostModal(false)}
        title={'Create Post'}
        footer={null}
      >
        <CreatePostModal item={vehicle} setShowModal={setShowPostModal} locationAccountsInfo={locationAccountsInfo} />
      </DefaultModal>
      <DefaultModal
        visible={showVideoPostModal}
        width={920}
        onCancel={() => setShowVideoPostModal(false)}
        title={'Post Video'}
        footer={null}
      >
        <VideoPostModal
          item={vehicle}
          setShowModal={setShowVideoPostModal}
          locationAccountsInfo={locationAccountsInfo}
        />
      </DefaultModal>
    </React.Fragment>
  );
};
