import React, { useImperativeHandle } from 'react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { UploadAvatarResponse } from '../../../../graphql/mutations/uploadAvatar';
import { FormComponentProps, FormRef } from '../../../../models/form';
import { RoleEnum } from '../../../../models/session';
import RadioGroupHorizontalField from '../../RadioGroupHorizontalField';
import { Spacer } from '../../Spacer';
import TextField from '../../TextField';
import UploadButton from '../../UploadButton';
import { Select } from 'antd';
import { LabelBold } from '../../Text/Text.styled';
import { SelectOption } from '../../FormSelectField/FormSelectField';
import RadioGroupField from '../../RadioGroupField';
import { ToggleBoxOptions, toggleBoxOptions } from '../../../utils/enums.utils';
import { useParams } from 'react-router-dom';

const ValidationSchema = Yup.object().shape({
  fullName: Yup.string().default('').required(),
  email: Yup.string().default('').required(),
  phoneNumber: Yup.string().default('').notRequired(),
  ineligibleMarketplaceReportEnabled: Yup.number().nullable().default(ToggleBoxOptions.No),
  role: Yup.string().default('').required(),
  title: Yup.string().default('').optional(),
  avatarId: Yup.number().default(0).optional(),
  newPassword: Yup.string().default('').optional(),
  assignLicenseToLocationId: Yup.string().nullable().notRequired(),
});

export interface UpsertUserFormValue {
  id: string | null;
  fullName: string;
  email: string;
  phoneNumber: string;
  role: string;
  title: string;
  avatarId?: number;
  newPassword?: string;
  locationIds?: string[];
  assignLicenseToLocationId?: string;
  partnerId?: string;
  ineligibleMarketplaceReportEnabled: ToggleBoxOptions;
}

export interface UpsertUserFormProps extends FormComponentProps<UpsertUserFormValue> {
  locationOptions: SelectOption[];
  marketplaceLocationOptions: any;
}

const UpsertUserForm = React.forwardRef<FormRef<UpsertUserFormValue>, UpsertUserFormProps>(
  (props: UpsertUserFormProps, ref) => {
    const { handleSubmit, control, errors, formState, setValue } = useForm<UpsertUserFormValue>({
      resolver: yupResolver(ValidationSchema),
      mode: 'onTouched',
      defaultValues: {
        avatarId: props.initialValues?.avatarId,
        fullName: props.initialValues?.fullName,
        email: props.initialValues?.email,
        role: props.initialValues?.role,
        phoneNumber: props.initialValues?.phoneNumber,
        title: props.initialValues?.title,
        newPassword: undefined,
        locationIds: props.initialValues?.locationIds ?? [],
        partnerId: props.initialValues?.partnerId,
        ineligibleMarketplaceReportEnabled: props.initialValues?.ineligibleMarketplaceReportEnabled,
      },
    });

    const newUser = !props.initialValues?.id;

    const { partnerId: selectedPartnerId } = useParams<{ partnerId: string }>();
    let isPartnerUser = false;
    if (selectedPartnerId) {
      isPartnerUser = true;
    }

    let roleOptions = [
      {
        value: RoleEnum.Admin,
        label: RoleEnum.Admin,
      },
      {
        value: RoleEnum.Member,
        label: 'Team Member',
      },
    ];
    let defaultRole = RoleEnum.Admin;

    if (isPartnerUser) {
      roleOptions = [{ value: RoleEnum.Partner, label: 'Partner' }];
      defaultRole = RoleEnum.Partner;
    }

    const onSubmit = (data: UpsertUserFormValue) => {
      props.onCompleted(data);
    };
    const submitHandler = handleSubmit(onSubmit);
    useImperativeHandle(
      ref,
      () => ({
        formState,
        submit() {
          return submitHandler();
        },
      }),
      [formState, submitHandler]
    );

    const onFileChange = (data: UploadAvatarResponse) => {
      setValue('avatarId', data.uploadUserAvatar.id);
    };

    return (
      <form onSubmit={submitHandler}>
        <Controller
          render={() => {
            return <UploadButton size={'large'} onChange={onFileChange} />;
          }}
          name="avatarId"
          control={control}
          errors={errors}
        />
        <TextField
          type="text"
          name="fullName"
          label="FULL NAME"
          placeholder="John Doe"
          defaultValue=""
          control={control}
          errors={errors}
        />
        <Spacer type="stack" size="m" />
        <TextField
          type="email"
          name="email"
          label="EMAIL"
          placeholder="johndoe@mail.com"
          defaultValue=""
          control={control}
          errors={errors}
        />
        <Spacer type="stack" size="m" />
        <TextField
          type="text"
          name="phoneNumber"
          label="MOBILE NUMBER"
          placeholder="(555) 555-1234"
          defaultValue=""
          control={control}
          errors={errors}
        />
        <Spacer type="stack" size="m" />

        {!isPartnerUser && (
          <>
            <RadioGroupField
              control={control}
              name="ineligibleMarketplaceReportEnabled"
              label="Send Marketplace Ineligible Report"
              errors={errors}
              options={toggleBoxOptions}
            />
            <Spacer type="stack" size="m" />
            <LabelBold>LOCATIONS</LabelBold>
            <Controller
              name="locationIds"
              control={control}
              render={() => {
                return (
                  <Select
                    onChange={(value) => setValue('locationIds', value)}
                    defaultValue={props.initialValues?.locationIds}
                    style={{ width: '100%' }}
                    mode="tags"
                    tokenSeparators={[',']}
                    options={props.locationOptions}
                  ></Select>
                );
              }}
            />
            <>
              <Spacer type="stack" size="m" />
              <LabelBold>CONNECT TO MARKETPLACE</LabelBold>
              <Controller
                name="assignLicenseToLocationId"
                control={control}
                render={() => {
                  return (
                    <Select
                      onChange={(value) => setValue('assignLicenseToLocationId', value ?? null)}
                      defaultValue={props.initialValues?.assignLicenseToLocationId}
                      style={{ width: '100%' }}
                      options={props.marketplaceLocationOptions}
                      allowClear
                    ></Select>
                  );
                }}
              />
            </>

            <Spacer type="stack" size="m" />
          </>
        )}
        <RadioGroupHorizontalField
          name="role"
          label="ROLE TYPE"
          defaultValue={props.initialValues?.role || defaultRole}
          control={control}
          errors={errors}
          options={roleOptions}
        />
        <Spacer type="stack" size="l" />
        <TextField type="text" name="title" label="TITLE" placeholder="Title" control={control} errors={errors} />
        <Spacer type="stack" size="m" />
        {!newUser && (
          <>
            <TextField
              type="password"
              name="newPassword"
              label="CHANGE PASSWORD"
              placeholder="Password"
              control={control}
              errors={errors}
            />
            <Spacer type="stack" size="m" />
          </>
        )}
      </form>
    );
  }
);

export default UpsertUserForm;
