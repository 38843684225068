import { gql } from '@apollo/client';

import { LocationProductEnum, MarketplaceStats } from './locationsByOrgId';
import { NameId } from '../../components/utils/types';

export interface UsersResponseItem {
  id: string;
  username: string;
  organizationId?: string;
  receiveEmailNotifications: boolean;
  mailConfirmed: boolean;
  isActive: boolean;
  lastActiveAt: string | null;
  invitationLink?: string;
  avatarId?: string;
  fullName?: string;
  title?: string;
  phoneNumber?: string;
  isPoc: boolean;
  isPartnerPoc: boolean;
  marketplaceLicense?: {
    id: string;
    locationId: string;
    token: string;
    template?: string;
    footer?: string;
    header?: string;
  };
  marketplaceStats?: MarketplaceStats;
  avatar?: {
    id: number;
    signedUrl: string;
  };
  role: string;
  products: LocationProductEnum[];
  locations: NameId[];
  partnerId?: string;
  ineligibleMarketplaceReportEnabled: boolean;
  archivedAt?: Date;
}

export interface UsersByOrgIdResponse {
  usersByOrgId: UsersResponseItem[];
}

export interface UsersByOrgIdParams {
  organizationId: string;
  onlyActive?: boolean;
  username?: string;
}

export const UserFieldsFragment = gql`
  fragment UserFields on User {
    id
    username
    organizationId
    partnerId
    receiveEmailNotifications
    mailConfirmed
    isActive
    archivedAt
    invitationLink
    lastActiveAt
    fullName
    title
    phoneNumber
    avatarId
    ineligibleMarketplaceReportEnabled
    avatar {
      id
      signedUrl
    }
    role
    marketplaceLicense {
      id
      locationId
      token
    }
    marketplaceStats {
      totalPosted
      totalAvailableToPost
      totalPending
    }
    locations {
      id
      name
    }
    isPoc
    isPartnerPoc
  }
`;

export const USERS_BY_ORG_ID_QUERY_NAME = 'usersByOrgId';
export const USERS_BY_ORG_ID = gql`
  query ${USERS_BY_ORG_ID_QUERY_NAME}($organizationId: String!, $onlyActive: Boolean, $username: String) {
    usersByOrgId(organizationId: $organizationId, onlyActive: $onlyActive, username: $username) {
      ...UserFields

    }
  }
  ${UserFieldsFragment}
`;
