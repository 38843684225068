import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import SimpleCard from '../SimpleCard';
import { TagLabel } from '../TagLabel';
import { LabelBodyB, LabelBodyR } from '../Text/Text.styled';

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 8px 16px;
`;
const RowContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  padding: 8px 0;
`;

const LabelBodyBStyled = styled(LabelBodyB)`
  color: ${(props) => props.theme.colors.dimGray.minus1};
`;
const LabelBodyRStyled = styled(LabelBodyR)`
  color: ${(props) => props.theme.colors.dimGray.base};
`;

const SimpleCardStyled = styled(SimpleCard)`
  margin: 0 0 32px 0;
`;

export interface OrganizationGeneralCardProps {
  isActive: boolean;
  website: string;
  city?: string;
  state?: string;
  country?: string;
  headquarters?: string;
  archivedAt?: Date;
  createdAt?: Date;
}

const OrganizationGeneralCard: React.FC<OrganizationGeneralCardProps> = ({
  isActive,
  website,
  city,
  state,
  country,
  headquarters,
  archivedAt,
  createdAt,
}) => {
  const locationString = city && state && country ? `${city}, ${state} ${country}` : headquarters || '';

  return (
    <SimpleCardStyled topHeader="General">
      <CardContainer>
        <RowContainer>
          <LabelBodyBStyled>Status</LabelBodyBStyled>
          <TagLabel color={isActive ? 'aquamarine' : 'red'}>{isActive ? 'Active' : 'Inactive'}</TagLabel>
        </RowContainer>
        <RowContainer>
          <LabelBodyBStyled>Domain</LabelBodyBStyled>
          <LabelBodyRStyled>{website}</LabelBodyRStyled>
        </RowContainer>
        <RowContainer>
          <LabelBodyBStyled>Headquarters</LabelBodyBStyled>
          <LabelBodyRStyled>{locationString}</LabelBodyRStyled>
        </RowContainer>
        <RowContainer>
          <LabelBodyBStyled>Created On</LabelBodyBStyled>
          <LabelBodyRStyled>{moment(createdAt).format('MM/DD/YY')}</LabelBodyRStyled>
        </RowContainer>

        {archivedAt && (
          <RowContainer>
            <LabelBodyBStyled>Archived On</LabelBodyBStyled>
            <LabelBodyRStyled>{moment(archivedAt).format('MM/DD/YY')}</LabelBodyRStyled>
          </RowContainer>
        )}
      </CardContainer>
    </SimpleCardStyled>
  );
};

export default OrganizationGeneralCard;
