import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useMutation, useQuery } from '@apollo/client';
import styled from 'styled-components';
import { usePostStateContext } from '../../../context/PostStateProvider';
import {
  REFRESH_INVENTORY,
  RefreshInventoryRequest,
  RefreshInventoryResponse,
} from '../../../graphql/mutations/updateInventory';
import { useLocationAccountsInfo } from '../../../graphql/query/getLocationAccountsInfoInfo';
import {
  Condition,
  INVENTORY_BY_LOCATION_ID,
  InventoryByLocationIdParams,
  InventoryByLocationIdResponse,
  InventoryItem,
} from '../../../graphql/query/inventoryByLocationId';
import { useKeywordsByLocationId } from '../../../graphql/query/keywordsByLocationId';
import { RoleEnum } from '../../../models/session';
import { VehicleFilters } from '../../../pages/Inventory/hooks/useVehicleFilters';
import IconButtonSimple from '../IconButtonSimple';
import { HasPermission } from '../Security';
import { Spacer } from '../Spacer';
import { LabelB } from '../Text/Text.styled';
import { SearchInput } from '../TextInput/SearchInput.styled';
import { VehicleGridView } from './modeViews/VehicleGridView';
import { VehicleTableView } from './modeViews/VehicleTableView';
import { applyFilters } from './utils/filters';
import { useParams } from 'react-router-dom';
import { displayGqlErrors } from '../ErrorList';
import { useButtonTimeout } from '../../../hooks/useButtonTimeout';
import { Permission, useHasPermission } from '../../../hooks/useHasPermission';

const PageContent = styled.div`
  padding: 10px 10px;
  align-items: flex-start;
`;
const PageHeader = styled.div`
  width: 100%;
  height: 56px;
  padding: 12px 16px;
  border-bottom: 1px solid ${(props) => props.theme.colors.dimGray.plus3};
  display: flex;
  align-items: center;

  justify-content: space-between;
`;
const SyncOptions = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 8px;
  align-items: center;
  width: 200px;
  justify-content: flex-end;
`;
interface VehicleColumnProps {
  vehicleFilters: VehicleFilters;
}

interface ItemsSelected extends InventoryItem {
  active?: false;
}

export const VehicleColumn: React.FC<VehicleColumnProps> = ({ vehicleFilters }) => {
  const { locationId } = useParams<{ locationId?: string }>();
  const { mode, condition, selectedFilters: filters } = vehicleFilters;
  const { isDisabled: disableRefreshButton, onClick: onRefreshClick } = useButtonTimeout(
    3,
    `inventory-refresh=${locationId}`
  );
  const postState = usePostStateContext();
  const [vehicles, setVehicles] = useState<ItemsSelected[]>([]);
  const [refresh] = useMutation<RefreshInventoryResponse, RefreshInventoryRequest>(REFRESH_INVENTORY);
  const {
    data,
    refetch,
    error,
    loading: loadingInventory,
  } = useQuery<InventoryByLocationIdResponse, InventoryByLocationIdParams>(INVENTORY_BY_LOCATION_ID, {
    variables: {
      locationId: locationId ?? '',
      condition: condition === Condition.All ? undefined : condition,
    },
  });

  const { keywords } = useKeywordsByLocationId({ locationId: locationId! });
  postState?.actions.changeKeywords(keywords!);
  locationId && postState?.actions.changeLocationId(locationId);
  const { locationAccountsInfo, loading } = useLocationAccountsInfo(locationId!);
  const lastSyncDate = locationAccountsInfo?.location?.dealer?.lastSync;
  const createdAt = locationAccountsInfo?.location.organization.createdAt;

  const canUseInventoryActions = useHasPermission(Permission.CanUseInventoryActions);
  const isLegacyOrg = moment(createdAt).isBefore(moment().subtract(1, 'month'));
  const showLastSync = isLegacyOrg || canUseInventoryActions;

  const refreshInventory = () => {
    if (!locationId) return;
    refresh({
      variables: { locationId: locationId },
    });
    onRefreshClick();
  };
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.trim();
    if (data) {
      refetch({
        locationId: locationId,
        condition: condition === Condition.All ? undefined : condition,
        name: value,
      });
    }
  };
  useEffect(() => {
    if (data) {
      setVehicles(data.getInventoryByLocationId.filter((item) => applyFilters(item, filters)));
    }
    if (!data && error) {
      displayGqlErrors(error, `Unable to fetch inventory`);
    }
  }, [data, filters, error]);

  if (!locationId) return null;

  if (error) {
    return <div>Something went wrong .</div>;
  }

  return !loading ? (
    <>
      <PageHeader>
        <SearchInput
          placeholder="Search inventory by vehicle name, stock number, VDP LINK"
          onChange={handleChange}
          removeBorder
        />
        <HasPermission allowedRoles={[RoleEnum.Admin, RoleEnum.SuperAdmin, RoleEnum.FacebookTest, RoleEnum.Partner]}>
          <SyncOptions>
            {showLastSync && <LabelB>Last Sync: {lastSyncDate ? moment(lastSyncDate).fromNow() : 'Never'}</LabelB>}
            <HasPermission allowedRoles={[RoleEnum.SuperAdmin]}>
              <Spacer type="inline" size="xs" />
              <IconButtonSimple
                disabled={disableRefreshButton}
                onClick={refreshInventory}
                icon="reload"
              ></IconButtonSimple>
            </HasPermission>
          </SyncOptions>
        </HasPermission>
      </PageHeader>
      <PageContent>
        {mode === 'Table' ? (
          <VehicleTableView
            loadingInventory={loadingInventory}
            vehicles={vehicles || []}
            locationAccountsInfo={locationAccountsInfo!}
          />
        ) : (
          <VehicleGridView vehicles={vehicles || []} locationAccountsInfo={locationAccountsInfo!} />
        )}
      </PageContent>
    </>
  ) : (
    <></>
  );
};
