/* eslint-disable @typescript-eslint/no-unused-vars */
import { gql, useQuery } from '@apollo/client';
import { TikTokBusinessCenter } from './businessCenter';
import { MarketplaceInventoryDistribution } from '../../components/ui/Forms/NewLocationForm';

export enum InventorySource {
  MARKETCHECK = 'marketcheck',
  HOMENET = 'homenet',
  EDEALER = 'edealer',
  DEALERSPIKE = 'dealerSpike',
  LESA = 'Lesa',
  DX1 = 'dx1',
}

export type Dealer = {
  externalId: string;
  name: string;
  inventoryUrl: string;
  source: InventorySource;
  type: DealerType;
  syncNonVinListings: boolean;
  syncOwnedListings: boolean;
  discardFirstXImages?: number;
  discardLastXImages?: number;
};

export enum DealerType {
  car = 'car',
  motorcycle = 'motorcycle',
  heavy_vehicle = 'heavy_vehicle',
  powersports = 'powersports',
  rv = 'rv',
  boat = 'boat',
}

export enum LocationProductEnum {
  Facebook = 'facebook',
  Instagram = 'instagram',
  Google = 'google',
  FbMarketplace = 'facebook_marketplace',
  FacebookAds = 'facebook_ads',
  GoogleAds = 'google_ads',
  TikTok = 'tik_tok',
  LeadRevival = 'lead_revival',
}

export type MarketplaceStats = {
  totalPosted: number;
  totalAvailableToPost: number;
  totalPending: number;
};

export type TagManagerStatus = {
  id: string;
  name: string;
  tik_tok: boolean;
};

export interface FacebookSettings {}
export interface GoogleSettings {
  carsForSaleEnabled: boolean;
}

export interface InstagramSettings {}
export interface FacebookMarketplaceSettings {
  dailyPostNumber: number;
  inventoryDistribution: MarketplaceInventoryDistribution;
  imageRequirement: number;
}

export interface TikTokSettings {}

export interface ProductSettings {
  facebook?: FacebookSettings;
  google?: GoogleSettings;
  instagram?: InstagramSettings;
  facebook_marketplace?: FacebookMarketplaceSettings;
  tik_tok?: TikTokSettings;
}
export interface LocationProducts {
  slug: LocationProductEnum;
  settings: string;
  enabled: boolean;
}

export interface MarketplaceLicense {
  id: string;
  token: string;
  paused: boolean;
  skipWarmupPeriod: boolean;
  marketplaceLocation?: string;
  user?: {
    id: string;
    fullName?: string;
    username?: string;
  };
}

export interface LocationItem {
  id: string;
  name: string;
  address: string;
  state: string;
  city: string;
  zip: string;
  country: string;
  organizationId: string;
  autoPostsEnabled: boolean;
  thirtyDayCallCompleted: boolean;
  crmReportsConnected: boolean;
  leadRevivalTextEnabled: boolean;
  inventoryProvider: string;
  keywords: string;
  marketplaceTemplate?: string;
  dealerMessage: string;
  website: string;
  serviceCenterUrl: string;
  homenetId: string;
  type: string;
  marketplaceLicenses: MarketplaceLicense[];
  unbrandedEnabled: boolean;
  tagManagerStatus?: TagManagerStatus;
  pocUserId?: string;
  tikTokCreator?: {
    id: string;
    name: string;
  };

  customTemplate?: {
    fbFooter?: string;
    googleFooter?: string;
    marketplaceFooter?: string;
    marketplaceHeader?: string;
  };

  facebookPage?: {
    pageId: string;
    name: string;
    hasInstagramPage: boolean;
  };

  googleLocation?: {
    id: string;
    locationName: string;
  };

  merchantCenter?: {
    id: string;
    feedApproved: boolean;
  };

  googleAdAccount?: {
    id: string;
  };

  facebookAdAccountId?: string;

  businessCenter?: TikTokBusinessCenter;
  dealer?: Dealer;
  products: LocationProducts[];
  marketplaceStats?: MarketplaceStats;
  users?: {
    id: string;
  }[];
  resellerPrice?: number;
  archiveAt?: Date;
  archivedAt?: Date;
  createdAt: Date;
  shopId?: string;
  catalogId?: string;
  tikTokStoreId?: string;
  tikTokPixelId?: string;
  facebookPixelId?: string;
  connectedMarketplace: boolean;
  dealershipNumber: string;
  fbMarketplaceLocation?: string;
  postSettings: string;
  activeInventoryCount?: number;
  adfEmail?: string;
  crm?: string;
  crmUsername?: string;
  crmPassword?: string;
  crmFreeText?: string;
  contactUsUrl?: string;
  serviceUrl?: string;
  serviceNumber?: string;
  tradeInUrl?: string;
  leaseUrl?: string;
  marketingEmail?: string;
  replyToEmail?: string;
  canCreateMoreLicenses?: boolean;
}

export interface LocationByOrgIdResponse {
  locationsByOrgId: LocationItem[];
}
export interface LocationByOrgIdParams {
  organizationId: string;
  active?: boolean;
}

export const LocationFieldsFragment = gql`
  fragment LocationFields on Location {
    id
    name
    address
    state
    city
    zip
    country
    pocUserId
    organizationId
    autoPostsEnabled
    thirtyDayCallCompleted
    unbrandedEnabled
    crmReportsConnected
    leadRevivalTextEnabled
    website
    keywords
    inventoryProvider
    homenetId
    shopId
    serviceCenterUrl
    type
    dealershipNumber
    fbMarketplaceLocation
    adfEmail
    crmUsername
    crmPassword
    crm
    crmFreeText
    contactUsUrl
    serviceUrl
    serviceNumber
    tradeInUrl
    leaseUrl
    marketingEmail
    replyToEmail
    products {
      slug
      settings
      enabled
    }
    marketplaceStats {
      totalPosted
      totalAvailableToPost
      totalPending
    }
    catalogId
    connectedMarketplace
    tikTokStoreId
    tikTokPixelId
    facebookPixelId
    facebookAdAccountId
    users {
      id
    }
    marketplaceLicenses {
      id
      paused
      token
      skipWarmupPeriod
      marketplaceLocation
      user {
        id
        fullName
      }
    }
    tagManagerStatus {
      id
      name
      tik_tok
    }
    tikTokCreator {
      id
      name
    }
    facebookPage {
      pageId
      name
      hasInstagramPage
    }
    merchantCenter {
      id
      feedApproved
    }
    googleAdAccount {
      id
    }
    businessCenter {
      name
      id
    }
    googleLocation {
      id
      locationName
    }
    dealer {
      id
      externalId
      name
      inventoryUrl
      source
      type
      syncNonVinListings
      syncOwnedListings
      discardFirstXImages
      discardLastXImages
    }
    customTemplate {
      fbFooter
      googleFooter
      marketplaceFooter
      marketplaceHeader
    }
    marketplaceTemplate
    dealerMessage
    archiveAt
    archivedAt
    createdAt
    postSettings
    activeInventoryCount
    canCreateMoreLicenses
    resellerPrice
  }
`;

export const LOCATIONS_BY_ORG_ID_NAME = 'locationsByOrgId';
export const LOCATIONS_BY_ORG_ID = gql`
  query ${LOCATIONS_BY_ORG_ID_NAME}($organizationId: String!, $active: Boolean) {
    ${LOCATIONS_BY_ORG_ID_NAME}(organizationId: $organizationId, active: $active) {
      ...LocationFields
    }
  }
  ${LocationFieldsFragment}
`;
export function useLocationByOrgIdQuery(organizationId: string, active = true) {
  const { data, error, loading, refetch } = useQuery<LocationByOrgIdResponse, LocationByOrgIdParams>(
    LOCATIONS_BY_ORG_ID,
    {
      variables: {
        organizationId: organizationId,
        active,
      },
    }
  );
  return {
    locations: data?.locationsByOrgId || [],
    error,
    loading,
    refetch,
  };
}
