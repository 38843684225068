import React, { useState } from 'react';
import { Modal, Select, Typography } from 'antd';
import PostDescriptionBuilder from '../../AccountInformationCard/PostDescriptionBuilder';
import { useMutation } from '@apollo/client';
import { SAVE_MARKETPLACE_LOCATION_TEMPLATE } from '../../../../graphql/mutations/saveMarketplaceLocationTemplate';
import { snackbar } from '../../Snackbar';
import * as Sentry from '@sentry/react';
import { useLocationByOrgIdQuery } from '../../../../graphql/query/locationsByOrgId';

const { Title } = Typography;

interface MarketplaceListingCustomizationModalProps {
  isOpen: boolean;
  onCancel: () => void;
  organizationId: string;
  locationId?: string;
}

export const MarketplaceListingCustomizationModal: React.FC<MarketplaceListingCustomizationModalProps> = ({
  isOpen,
  onCancel,
  organizationId,
  locationId,
}) => {
  const [selectedLocation, setSelectedLocation] = useState<string | null>(locationId || null);
  const [saveTemplate] = useMutation(SAVE_MARKETPLACE_LOCATION_TEMPLATE);

  const { locations } = useLocationByOrgIdQuery(organizationId);

  const handleSave = async ({ template, header, footer }: { template: string; header: string; footer: string }) => {
    if (!selectedLocation) return;

    try {
      const response = await saveTemplate({
        variables: {
          locationId: selectedLocation,
          template,
          header,
          footer,
        },
      });

      if (response.data.saveMarketplaceLocationTemplate) {
        snackbar.success({
          message: 'Template saved successfully',
        });
      } else {
        snackbar.error({
          message: 'Failed to save template',
        });
      }
    } catch (error) {
      Sentry.captureException(error);
      snackbar.error({
        message: 'Failed to save template',
      });
    }
  };

  const selectedLocationData = locations.find((location) => location.id === selectedLocation);

  return (
    <Modal
      title={<Title level={4}>Customize Marketplace Listings</Title>}
      open={isOpen}
      onCancel={onCancel}
      width={1000}
      footer={null}
    >
      <div style={{ marginBottom: '24px' }}>
        <Select
          style={{ width: '100%' }}
          placeholder="Select a location"
          value={selectedLocation}
          onChange={setSelectedLocation}
          options={locations.map((location) => ({
            label: location.name,
            value: location.id,
          }))}
        />
      </div>

      {selectedLocation && selectedLocationData && (
        <PostDescriptionBuilder
          template={selectedLocationData.marketplaceTemplate || ''}
          header={selectedLocationData.customTemplate?.marketplaceHeader || ''}
          footer={selectedLocationData.customTemplate?.marketplaceFooter || ''}
          handleSave={handleSave}
        />
      )}
    </Modal>
  );
};
