import React, { useState, useMemo } from 'react';
import { Redirect, Route, Switch, useParams, useRouteMatch } from 'react-router-dom';
import UsersLayout from '../components/Layout/UsersLayout';
import { UserList } from '../pages/UserList';
import { useQuery } from '@apollo/client';
import { USERS_BY_ORG_ID, UsersByOrgIdParams } from '../graphql/query/usersByOrganizationId';
import { USERS_BY_PARTNER_ID, UsersByPartnerIdParams } from '../graphql/query/usersByPartnerId';

const UserRoutes: React.FC = () => {
  const { path } = useRouteMatch();
  const { organizationId, partnerId } = useParams<{ partnerId: string; organizationId: string }>();
  const [selectedLocationIds, setSelectedLocationIds] = useState<string[]>([]);

  let variables: UsersByOrgIdParams | UsersByPartnerIdParams = {
    organizationId,
    onlyActive: true,
  };
  let queryName = USERS_BY_ORG_ID;

  if (partnerId) {
    variables = {
      partnerId,
      onlyActive: true,
    };
    queryName = USERS_BY_PARTNER_ID;
  }

  const { data, refetch } = useQuery(queryName, {
    variables,
  });

  const users = data?.usersByOrgId || data?.usersByPartnerId;

  const filteredUsers = useMemo(() => {
    if (!selectedLocationIds.length) return users;
    return users?.filter((user: any) =>
      user.locations?.some((location: any) => selectedLocationIds.includes(location.id))
    );
  }, [users, selectedLocationIds]);

  const handleLocationFilter = (locationIds: string[]) => {
    setSelectedLocationIds(locationIds);
  };

  return (
    <UsersLayout onLocationFilter={handleLocationFilter}>
      <Switch>
        <Route exact path={`${path}`}>
          <UserList users={filteredUsers} refetch={refetch} />
        </Route>
        <Redirect to={`${path}`} />
      </Switch>
    </UsersLayout>
  );
};

export default UserRoutes;
