import React, { useState } from 'react';

import styled from 'styled-components';

import { SideDrawerProvider } from '../../../context/SideDrawerProvider';
import { useUserCountByOrgId } from '../../../graphql/query/userCountByOrgId';
import { useLocationByOrgIdQuery } from '../../../graphql/query/locationsByOrgId';
import { LabelBodyB } from '../../ui/Text/Text.styled';
import { Checkbox } from 'antd';
import UserNavigationLink from './UserNavigationLink';
import { useParams } from 'react-router-dom';

const UsersLayoutWrapper = styled.section`
  height: 100%;
  display: flex;
`;
const SideContent = styled.aside`
  height: 100%;
  width: 200px;
  flex-shrink: 0;
  border-right: 1px solid ${(props) => props.theme.colors.dimGray.plus3};
`;
const LayoutContent = styled.section`
  height: 100%;
  flex-grow: 1;
`;

const UserNavigationHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 16px;
`;

const LocationFiltersSection = styled.div`
  padding: 16px;
  border-top: 1px solid ${(props) => props.theme.colors.dimGray.plus3};
`;

const LocationFilterTitle = styled(LabelBodyB)`
  margin-bottom: 8px;
`;

const LocationCheckboxGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

interface UsersLayoutProps {
  children: React.ReactNode;
  onLocationFilter?: (locationIds: string[]) => void;
}

const UsersLayout: React.FC<UsersLayoutProps> = ({ children, onLocationFilter }) => {
  const { organizationId } = useParams<{ organizationId: string }>();
  const { userCount } = useUserCountByOrgId(organizationId || '');
  const { locations } = useLocationByOrgIdQuery(organizationId || '');
  const [selectedLocations, setSelectedLocations] = useState<string[]>([]);

  const handleLocationChange = (locationId: string, checked: boolean) => {
    const newSelectedLocations = checked
      ? [...selectedLocations, locationId]
      : selectedLocations.filter((id) => id !== locationId);

    setSelectedLocations(newSelectedLocations);
    onLocationFilter?.(newSelectedLocations);
  };

  return (
    <SideDrawerProvider>
      <UsersLayoutWrapper>
        <SideContent>
          <UserNavigationHeader>
            <LabelBodyB>People</LabelBodyB>
          </UserNavigationHeader>
          <UserNavigationLink icon="users" label="Users" count={userCount} href="/app/users" />
          <LocationFiltersSection>
            <LocationFilterTitle>Filter by Location</LocationFilterTitle>
            <LocationCheckboxGroup>
              {locations.map((location) => (
                <Checkbox
                  style={{ margin: 0 }}
                  key={location.id}
                  onChange={(e) => handleLocationChange(location.id, e.target.checked)}
                  checked={selectedLocations.includes(location.id)}
                >
                  {location.name}
                </Checkbox>
              ))}
            </LocationCheckboxGroup>
          </LocationFiltersSection>
        </SideContent>
        <LayoutContent>{children}</LayoutContent>
      </UsersLayoutWrapper>
    </SideDrawerProvider>
  );
};

export default UsersLayout;
