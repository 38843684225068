import JSZip from 'jszip';
import axios from 'axios';

export async function saveUrlsToZip(zipName: string, urls: string[]): Promise<void> {
  const zip = new JSZip();

  // Save each URL as a file in the ZIP archive
  for (let i = 0; i < urls.length; i++) {
    const url = urls[i];

    try {
      const response = await axios.get(url, { responseType: 'arraybuffer' });
      const contentType = response.headers['content-type'] || '';
      const extension = contentType.split('/').pop();
      const filename = `${i}.${extension}`;

      zip.file(filename, response.data);
      console.log(`Added ${url} as ${filename}`);
    } catch (error) {
      console.error(`Failed to add ${url}:`, error);
    }
  }

  // Generate the ZIP archive and offer it as a download
  const content = await zip.generateAsync({ type: 'blob' });
  const link = document.createElement('a');
  link.href = URL.createObjectURL(content);
  link.download = zipName;
  link.click();
}

export const createDownloadLink = (csv: string, fileName: string) => {
  const blob = new Blob([csv], { type: 'text/csv' });
  const link = document.createElement('a');
  const url = window.URL.createObjectURL(blob);
  link.href = url;
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  link.parentNode?.removeChild(link);
  window.URL.revokeObjectURL(url);
};
