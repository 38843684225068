import React from 'react';
import { Card, Typography, Button, Space } from 'antd';
import { MailOutlined, MessageOutlined, YoutubeOutlined } from '@ant-design/icons';

const { Title, Text, Link } = Typography;

const TEXT_NUMBER = '+14352911432';
const SUPPORT_EMAIL = 'support@localshift.io';
const DASHBOARD_TUTORIAL_LINK = 'https://www.youtube.com/playlist?list=PLmaBQZHFAprKNqroZKFOtl1CrreeP48bK';
const KNOWLEDGE_CENTER_LINK = 'https://localshift.document360.io/docs/';

const HelpCenter: React.FC = () => {
  return (
    <div style={{ backgroundColor: '#f5f5f5', minHeight: '100vh', padding: '40px' }}>
      <Card
        style={{
          maxWidth: 600,
          margin: '0 auto',
          borderRadius: 8,
          boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
        }}
        bodyStyle={{ backgroundColor: 'white', padding: 20 }}
      >
        <Title level={3} style={{ color: 'rgb(74, 79, 137)', textAlign: 'center' }}>
          Help Center
        </Title>
        <Space direction="vertical" size="middle" style={{ width: '100%' }}>
          <div>
            <Text strong>Knowledge Center</Text>
            <br />
            <Button type="link" onClick={() => window.open(KNOWLEDGE_CENTER_LINK, '_blank')}>
              Visit Knowledge Center
            </Button>
          </div>
          <div>
            <Text strong>
              <YoutubeOutlined style={{ color: 'rgb(74, 79, 137)' }} /> Tutorial Videos:
            </Text>
            <br />
            <Link href={DASHBOARD_TUTORIAL_LINK} target="_blank">
              Watch Dashboard Tutorial
            </Link>
          </div>
          <div>
            <Text strong>
              <MailOutlined style={{ color: 'rgb(74, 79, 137)' }} /> Email:
            </Text>
            <br />
            <Link href={`mailto:${SUPPORT_EMAIL}`} style={{ color: 'rgb(74, 79, 137)' }}>
              {SUPPORT_EMAIL}
            </Link>
          </div>
          <div>
            <Text strong>
              <MessageOutlined style={{ color: 'rgb(74, 79, 137)' }} /> Text Us:
            </Text>
            <br />
            <Link href={`sms:${TEXT_NUMBER}`} style={{ color: 'rgb(74, 79, 137)' }}>
              {TEXT_NUMBER}
            </Link>
          </div>
        </Space>
      </Card>
    </div>
  );
};

export default HelpCenter;
