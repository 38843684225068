import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { SideMenuLayout } from '../../components/Layout/SideMenuLayout';
import { HealthScoreColumn } from './HealthScoreColumn/HealthScoreColumn';
import { RadioGroup, RadioGroupOption } from '../../components/ui/RadioGroup/RadioGroup';
import { RadioChangeEvent, Select, Modal, Button, Tag } from 'antd';
import { Spacer } from '../../components/ui/Spacer';
import {
  HealthLevel,
  HealthReasonType,
  LocationHealthSummary,
  useLocationHealthSummaryQuery,
} from '../../graphql/query/locationHealthSummaries';
import { useSuperAdminsQuery } from '../../graphql/query/superAdmins';
import { LabelBodyB } from '../../components/ui/Text/Text.styled';

export const HEALTH_REASON_OPTIONS: Record<HealthReasonType, { value: string; label: string }> = {
  [HealthReasonType.InventorySynced]: {
    value: HealthReasonType.InventorySynced,
    label: 'Inventory Not Synced',
  },
  [HealthReasonType.HasInventory]: {
    value: HealthReasonType.HasInventory,
    label: 'No Inventory',
  },
  [HealthReasonType.HasRecentPosts]: {
    value: HealthReasonType.HasRecentPosts,
    label: 'No Recent Posts',
  },
  [HealthReasonType.ListingToGroups]: {
    value: HealthReasonType.ListingToGroups,
    label: 'Not Listing To Groups',
  },
  [HealthReasonType.ListEverythingForEveryoneEnabled]: {
    value: HealthReasonType.ListEverythingForEveryoneEnabled,
    label: 'List Everything For Everyone Disabled',
  },
  [HealthReasonType.UserTokenHealth]: {
    value: HealthReasonType.UserTokenHealth,
    label: 'Poor User Token Health',
  },
  [HealthReasonType.HadThirtyDayCall]: {
    value: HealthReasonType.HadThirtyDayCall,
    label: 'No Thirty Day Call',
  },
  [HealthReasonType.NumberOfActiveUsers]: {
    value: HealthReasonType.NumberOfActiveUsers,
    label: 'Low Active Users Count',
  },
};

const MenuWrapper = styled.div`
  padding: 16px 16px;
  width: 100%;
`;

const FilterWrapper = styled.div`
  align-items: left;
  text-align: left;
`;

enum AccountType {
  ALL = 'ALL',
  PARTNER = 'Partner',
}

const accountTypeOptions: RadioGroupOption[] = [
  { label: 'All', value: AccountType.ALL },
  { label: 'Partner', value: AccountType.PARTNER },
];

const HealthScoreDashboard: React.FC = () => {
  const [accountType, setAccountType] = useState<AccountType>(AccountType.ALL);
  const [searchValue, setSearchValue] = useState<string>('');
  const { locationHealthSummaries: healthSummaries, refetch, error } = useLocationHealthSummaryQuery({});
  const [filters, setFilters] = useState<{
    healthLevel?: HealthLevel[];
    assignedUser?: string;
    reasons?: HealthReasonType[];
    reasonsHealthLevel?: HealthLevel[];
    showDismissed?: boolean;
  }>({});

  const { superAdmins } = useSuperAdminsQuery();
  const superAdminOptions = superAdmins.map((superAdmin) => ({
    label: superAdmin.fullName || superAdmin.username,
    value: superAdmin.id,
  }));

  const handleHealthLevelChange = (selectedLevels: HealthLevel[]) => {
    setFilters((prev) => ({ ...prev, healthLevel: selectedLevels.length ? selectedLevels : undefined }));
  };

  const handleHealthReasonChange = (selectedReasons: HealthReasonType[]) => {
    setFilters((prev) => ({ ...prev, reasons: selectedReasons.length ? selectedReasons : undefined }));
  };

  const onSelectReasonHealthLevel = (selectedLevels: HealthLevel[]) => {
    setFilters((prev) => ({ ...prev, reasonsHealthLevel: selectedLevels.length ? selectedLevels : undefined }));
  };

  const handleAssignedUserChange = (userId?: string) => {
    setFilters((prev) => ({ ...prev, assignedUser: userId || undefined }));
  };

  const handleShowDismissedChange = () => {
    setFilters((prev) => ({ ...prev, showDismissed: !prev.showDismissed }));
  };

  const clearFilters = () => {
    setFilters({});
  };

  const [filteredSummaries, setFilteredSummaries] = useState<LocationHealthSummary[]>([]);

  const redCount = healthSummaries.filter((summary) => summary.healthLevel === HealthLevel.Red).length;
  const yellowCount = healthSummaries.filter((summary) => summary.healthLevel === HealthLevel.Yellow).length;
  const greenCount = healthSummaries.filter((summary) => summary.healthLevel === HealthLevel.Green).length;

  useEffect(() => {
    setFilteredSummaries(healthSummaries);
  }, [healthSummaries]);

  useEffect(() => {
    refetch({
      partnersOnly: AccountType.PARTNER === accountType,
      search: searchValue,
    });
  }, [searchValue, refetch, accountType]);

  useEffect(() => {
    const filteredSummaries = healthSummaries.filter((summary) => {
      const matchesDismissedFilter = filters.showDismissed || !summary.dismissedAt;
      return (
        matchesDismissedFilter &&
        (!filters.healthLevel || filters.healthLevel.includes(summary.healthLevel)) &&
        (!filters.assignedUser || summary.assignedUser?.id === filters.assignedUser) &&
        (!filters.reasons ||
          summary.healthReasons.some(
            (reason) =>
              filters.reasons?.some((f) => f === reason.type) &&
              (!filters.reasonsHealthLevel || filters.reasonsHealthLevel.includes(reason.severity as HealthLevel))
          ))
      );
    });

    setFilteredSummaries(filteredSummaries);
  }, [filters, error]);

  const handleChangeCondition = ({ target: { value } }: RadioChangeEvent) => {
    setAccountType(value);
  };

  return (
    <SideMenuLayout
      title="Health Summaries"
      sideColumn={
        <MenuWrapper>
          <RadioGroup
            size="middle"
            defaultValue={accountType}
            onChange={handleChangeCondition}
            options={accountTypeOptions}
          ></RadioGroup>
          <Spacer type="stack" size="xs" />
          <FilterWrapper>
            <Select
              mode="multiple"
              style={{ width: '100%' }}
              placeholder="Filter by Health Level"
              onChange={(values: HealthLevel[]) => handleHealthLevelChange(values as HealthLevel[])}
              options={[
                { label: `Green (${greenCount})`, value: HealthLevel.Green },
                { label: `Yellow (${yellowCount})`, value: HealthLevel.Yellow },
                { label: `Red (${redCount})`, value: HealthLevel.Red },
              ]}
            />
            <Spacer type="stack" size="xs" />
            <Select
              placeholder="Filter by Assigned User"
              allowClear
              onChange={handleAssignedUserChange}
              options={superAdminOptions}
            />
            <Spacer type="stack" size="xs" />
            <ModalSelect
              options={Object.values(HEALTH_REASON_OPTIONS)}
              onChange={handleHealthReasonChange}
              onSelectHealthLevel={onSelectReasonHealthLevel}
              selectedReasons={filters.reasons}
              selectedReasonsHealthLevel={filters.reasonsHealthLevel}
            ></ModalSelect>
            {filters.reasons && (
              <>
                {' '}
                <Spacer type="stack" size="s" />
                <div style={{ display: 'flex', flexWrap: 'wrap', gap: '8px' }}>
                  {filters?.reasons?.map((reason) => (
                    <Tag>{HEALTH_REASON_OPTIONS[reason].label}</Tag>
                  ))}
                </div>
              </>
            )}{' '}
            {filters.reasonsHealthLevel && (
              <>
                {' '}
                <Spacer type="stack" size="s" />
                <div style={{ display: 'flex', flexWrap: 'wrap', gap: '8px' }}>
                  {filters?.reasonsHealthLevel?.map((healthLevel) => {
                    const color = healthLevel === 'Green' ? 'green' : healthLevel === 'Yellow' ? 'yellow' : 'red';
                    return <Tag color={color}>{healthLevel}</Tag>;
                  })}
                </div>
              </>
            )}{' '}
            <Spacer type="stack" size="xs" />
            <Button
              type={filters.showDismissed ? 'primary' : 'default'}
              onClick={handleShowDismissedChange}
              style={{ marginBottom: '16px' }}
            >
              {filters.showDismissed ? 'Hide Dismissed' : 'Show Dismissed'}
            </Button>
            <Spacer type="stack" size="xs" />
            <Button onClick={clearFilters}>Clear Filters</Button>
          </FilterWrapper>
        </MenuWrapper>
      }
      contentColumn={
        <HealthScoreColumn
          healthSummaries={filteredSummaries}
          handleSearch={(value: string) => setSearchValue(value)}
        />
      }
    />
  );
};

export default HealthScoreDashboard;

interface ModalSelectProps {
  options: { value: string; label: string }[];
  onChange?: (value: HealthReasonType[]) => void;
  onSelectHealthLevel?: (value: HealthLevel[]) => void;
  selectedReasons?: HealthReasonType[];
  selectedReasonsHealthLevel?: HealthLevel[];
}

const ModalSelect: React.FC<ModalSelectProps> = ({
  options,
  onChange,
  onSelectHealthLevel,
  selectedReasons,
  selectedReasonsHealthLevel,
}) => {
  const [open, setOpen] = useState(false);

  return (
    <div>
      <Button onClick={() => setOpen(true)}>Filter by Health Reason</Button>
      <Modal
        width={600}
        bodyStyle={{ height: 500 }}
        open={open}
        onCancel={() => setOpen(false)}
        footer={null}
        title="Health Reason Filters"
      >
        <LabelBodyB>Health Reason</LabelBodyB>
        <Select
          mode="multiple"
          style={{ width: '100%' }}
          placeholder="Select health reasons"
          value={selectedReasons}
          onChange={onChange}
          options={options}
        />
        <Spacer type="stack" size="xs" />
        <LabelBodyB>Severity</LabelBodyB>
        <Select
          mode="multiple"
          style={{ width: '100%' }}
          placeholder="Select"
          value={selectedReasonsHealthLevel}
          onChange={onSelectHealthLevel}
          options={[
            { label: `Green`, value: HealthLevel.Green },
            { label: `Yellow`, value: HealthLevel.Yellow },
            { label: `Red`, value: HealthLevel.Red },
          ]}
        />
      </Modal>
    </div>
  );
};
