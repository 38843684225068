import React, { useEffect } from 'react';
import { message } from 'antd';
import { useHistory } from 'react-router-dom';
import { loginRequest } from '../../api/auth.api';
import { displayAxiosErrors } from '../../components/ui/ErrorList';
import { useSession } from '../../context/AppStateProvider';
import { useQueryParam } from 'use-query-params';
import { handleLoginResponse } from './LoginByLocalshift';

export const LoginByToken: React.FC = () => {
  const history = useHistory();
  const [marketplaceToken] = useQueryParam('token') as string[];
  const [redirect] = useQueryParam('redirect') as string[];
  const { loadSession } = useSession();

  useEffect(() => {
    const login = async () => {
      try {
        const response = await loginRequest({
          username: 'marketplaceToken',
          password: marketplaceToken || '',
        });
        await handleLoginResponse(response, loadSession);
        if (redirect) {
          history.push(redirect);
        } else {
          history.push('/app/profile');
        }
      } catch (err) {
        const error = displayAxiosErrors(err);
        if (error?.response?.data.statusCode === 401) {
          message.error(error?.response.data.message);
        } else {
          message.error('Invalid Token');
        }
        history.push('/login-localshift');
      }
    };
    login().catch(() => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <> </> // Todo : building an awesome loading session
  );
};
